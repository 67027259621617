<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-department :items="departments" @editForm="editForm" />
            <modal-department @refresh="refresh" />
            <modal-department-edit @refresh="refresh" :id="id" />
        </div>
            <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import TableDepartment from '@/views/component/Table/tableDepartment.vue'
import ModalDepartment from '@/views/component/Modal/ModalDepartment/modalDepartment.vue'
import ModalDepartmentEdit from '@/views/component/Modal/ModalDepartment/modalDepartmentEdit.vue'
export default {
    components: {
        TableDepartment,
        ModalDepartment,
        ModalDepartmentEdit,
    },
    data(){
        return {
            departments: [],
            showPreloader: false,
            id: undefined,
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                { key: 'name', label: 'Имя', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_active', label: 'Статус', sortable: true, thStyle: {width: '150px'}},
                { key: 'positions', label: 'Должности', sortable: true, thStyle: {width: '150px'}}
            ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        refresh(){
            this.showPreloader = true
            this.$http
                .get(`employee-group`)
                .then(res => {
                    this.departments = res.data
                    this.$store.commit('pageData/setdataCount', this.departments.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('departmentEdit')
        },
        sendToParent(tableData){
            this.departments = tableData
            this.$store.commit('pageData/setdataCount', this.departments.length)
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.departments = res.data
                        this.$store.commit('pageData/setdataCount', this.departments.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.departments = res.data
                    this.$store.commit('pageData/setdataCount', this.departments.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>