<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Добавить отдел" size="sm" :no-close-on-backdrop="true" hide-footer no-enforce-focus @show="opened">
            <b-row>
                <b-col>
                    <b-form-group>
                        <label>Наименование</label>
                        <b-form-input v-model="item.name" id="basicInput" placeholder="Наименование"/>
                    </b-form-group>    
                </b-col>
                <b-col cols="12">
                    <label>Должности</label>
                   <v-select
                        v-model="item.positions"
                        label="name"
                        placeholder="Должности"
                        multiple
                        :options="positions"
                        class="select-size-md"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary" @click="convertData">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        ToastificationContent
    },
    data(){
        return {
            submitButtonDisabled: false,
            item: {
                name: undefined,
                positions: []
            },
            positions: [{id: 1, name: 'd'}]
        }
    },
    methods: {
        createDepartment(positionObj){
            this.submitButtonDisabled = true
            this.$http
                .post(`employee-group`, positionObj)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.closeModal()
                    this.$emit('refresh')
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        convertData(){
            let positionObj = JSON.parse(JSON.stringify(this.item))
            positionObj.positions = JSON.stringify(positionObj.positions)
            this.createDepartment(positionObj)
        },
        opened(){
            this.$http
                .get('employee-group/positions')
                .then(res => {
                    this.positions = res.data
                })
            this.clearData()
        },
        closeModal(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData(){
            this.item= {
                name: undefined,
                positions: []
            }
        }
    },
}
</script>